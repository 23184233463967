import React from "react";
import WritingCardItem from "./item";
import Container from "../container";

import dearself from "../../images/writing/dearself.jpg";
import inmemory from "../../images/writing/inmemory.jpg";
import story from "../../images/writing/story.jpg";
import uxwriting from "../../images/writing/uxwriting.jpg";

function WritingCards() {
  const list = [
    {
      image: `${inmemory}`,
      heading: "In Memory of Her",
      subheading: "In loving memory of her infested mind.",
      description:
        "On my tombstone, I lay these bouquets ; withered orchids and starved roses. visible and substantial enough to express a great divide. an exit with an apparent fight, unchallenging. I liked to assume that the process of dying is intimately ushered ...",
      url: "/writing/inmemory",
    },
    {
      image: `${dearself}`,
      heading: "Dear Self",
      subheading: "An open letter to myself",
      description:
        "Your scars will heal and on days when you poke it, it’ll cause no pain. You’ll find solace in your past, the memories that sting and the bumpy ride because it’s part of the process of being your better self. You’ll find strength to look into the eyes ...",
      url: "/writing/dearself",
    },
    {
      image: `${story}`,
      heading: "Do you like your story?",
      subheading: "No, I’m not a fan of mine",
      description:
        "Everyone loves a good story but not everyone likes theirs. I don’t like mine frankly. Do you? Some pages are marked with blood; pieces of me that I was hesitant to share. Lines that I have tried to cross out instead stand and taunt me. ",
      url: "/writing/story",
    },
    {
      image: `${uxwriting}`,
      heading: "UX Wrting- How to engage and sell",
      subheading: "A beginner’s guide to selling with your words",
      description:
        "Stringing words that perfectly communicates your desired messages and prompts your audience to tilt their focus and actions towards your envisioned direction is not a skill everyone has, but it can be mastered if consistent.",
      url: "/writing/juniordesigner",
    },
  ];

  return (
    <div className="writing__cards">
      <Container>
        <div className="writing__cards__content">
          {list.map((item) => {
            return <WritingCardItem
              img={item.image}
              heading={item.heading}
              subheading={item.subheading}
              description={item.description}
              url={item.url}
            />;
          })}
        </div>
      </Container>
    </div>
  );
}

export default WritingCards;
