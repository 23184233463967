import React, { Component } from 'react'
import Header from '../../components/writing/header'
import WritingCards from '../../components/writing/cards'

import "../../styles/writing.scss";
import Footer from '../../components/footer';

class Writing extends Component {
  render() {
    return (
      <main className="grey"> 
        <Header />
        <WritingCards />
        <Footer />
      </main>
    )
  }
}

export default Writing
