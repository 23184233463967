import React, { Component } from "react";
import { Link } from "gatsby";
import Nav from "../nav";
import Container from "../container";

import forevery from "../../images/writing/forevery.jpg";

export class header extends Component {
  render() {
    return (
      <div className="writing__header">
        <Nav />
        <Container>
          <h1>Writings from my desk</h1>
          <Link to="/writing/juniordesigner" className="writing__header__content">
            <div className="writing__header__left">
              <img src={forevery} alt="For every Junior Designer" />
            </div>
            <div className="writing__header__right">
              <h2>For every Junior Designer</h2>
              <p className="different">A resource filled article.</p>
              <p>
                Hello👋, I decided to put together a plethora of resources that
                have helped me in my Product Design journey. Hope you find it
                helpful. Let’s get started!
              </p>
              <p>
                User interface (UI) design according to Emil Lampretch, is a
                purely digital practice. It considers all the visual,
                interactive elements of a product interface – including buttons,
                icons, spacing, typography, color schemes, and responsive
                design.
              </p>
            </div>
          </Link>
        </Container>
        <div className="curve"></div>
      </div>
    );
  }
}

export default header;
