import React from "react";
import { Link } from "gatsby";

export default function WritingCardItem({
  img,
  heading,
  subheading,
  description,
  url,
}) {
  return (
    <Link to={url}>
      <div className="writing__cards__item">
        <img src={img} alt={heading} />
        <h4>{heading}</h4>
        <p className="different">{subheading}</p>
        <p>{description}</p>
      </div>
    </Link>
  );
}
